<template>
  <!-- 网点信息 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
    <!-- 新增修改弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="网点名称" prop="braesName" v-if="'braesName' in ruleForm"
          :rules="{required: true, message: '网点名称不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.braesName"></el-input>
        </el-form-item>
        <el-form-item label="快递编码" prop="kuaidiCom" v-if="'kuaidiCom' in ruleForm"
          :rules="{required: true, message: '请至少选择一个快递编码', trigger: 'change'}">
          <el-select v-model="ruleForm.kuaidiCom" placeholder="请选择">
            <el-option v-for="item in expressTypes" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
<!-- 		<el-form-item label="快递模板Id" prop="temId" v-if="'temId' in ruleForm"
		  :rules="{required: true, message: '快递模板Id不能为空', trigger: 'blur'}">
		  <el-input type="text" v-model="ruleForm.temId"></el-input>
		</el-form-item> -->
        <el-form-item label="网点账户" prop="partnerId" v-if="'partnerId' in ruleForm"
          :rules="{required: true, message: '网点账户不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.partnerId"></el-input>
        </el-form-item>
        <el-form-item label="网点密码" prop="partnerKey" v-if="'partnerKey' in ruleForm"
          :rules="{required: true, message: '网点密码不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.partnerKey"></el-input>
        </el-form-item>
        <el-form-item label="网点地址" prop="braesAddr" v-if="'braesAddr' in ruleForm"
          :rules="{required: true, message: '网点地址不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.braesAddr"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'draws',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      butLoading: false,
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
        { label: '网点名称', prop: 'braesName', align: 'left', 'show-overflow-tooltip': true },
        { label: '快递编码', prop: 'kuaidiCom', align: 'left', 'show-overflow-tooltip': true },
        // { label: '快递模板Id', prop: 'temId', align: 'left', 'show-overflow-tooltip': true },
        // {
        //   columnType: 'fun', label: '快递编码', prop: 'kuaidiCom', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
        //     let ele = this.expressTypes.find(ele => ele.value == e)
        //     return ele ? ele.key : e
        //   }
        // },
        { label: '网点账户', prop: 'partnerId', align: 'left', 'show-overflow-tooltip': true },
        { label: '网点密码', prop: 'partnerKey', align: 'left', 'show-overflow-tooltip': true },
        { label: '网点地址', prop: 'braesAddr', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogTitle: '新增',
      ruleForm: {
        braesName: '',//网点名称
        braesAddr: '',//网点地址
        kuaidiCom: '', //快递编码
        partnerId: '',//网点账户
        partnerKey: '',  //网点密码
		// temId:'',//快递模板id
        id: '',
      },
      // 类型 上架
      expressTypes: [{
        key: '顺丰',
        value: "shunfeng"
      }, {
        key: '中通',
        value: "zhongtong"
      }, {
        key: '圆通',
        value: "yuantong"
      }, {
        key: '汇通',
        value: "huitongkuaidi"
      }, {
        key: '申通',
        value: "shentong"
      }, {
        key: '韵达',
        value: "yunda"
      }, {
        key: '邮政',
        value: "ems"
      }, {
        key: '京东',
        value: "jd"
      }, {
        key: '宅急送',
        value: "zhaijisong"
      }, {
        key: '德邦',
        value: "debangkuaidi"
      }, {
        key: '闪送',
        value: "shansong"
      }, {
        key: '快服务',
        value: "kfw"
      }]
    }
  },
  mounted () {
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableList()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/cury/findCuryBraesList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          braesName: this.retrieveForm.searchValue,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //新增列表
    addList () {
      this.dialogTitle = '新增'
      this.dialogFormVisible = true
    },
    //编辑列表
    editList (row) {
      this.dialogTitle = '编辑'
      this.dialogFormVisible = true
      this.ruleForm = JSON.parse(JSON.stringify(row))   //排序
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/cury/removeCuryBraes', { id: row.id }).then(({ data: result }) => {
          this.getTableList()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          this.$http.post('/cury/saveOrEditCuryBraes', this.ruleForm).then((result) => {
            this.dialogFormVisible = false
            this.getTableList()
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.butLoading = false
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate() //清除校验
      })
    },
    //表格跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>